import React from 'react';
import { TextField, Autocomplete } from '@mui/material';
import PlayerStatsCard from './playerStatsCard'; // Import the updated component

const playerSuggestions = [
  'Daryl Tan',
  'Davin Palakal',
  'Emily Woolf',
  'Harry Newman-Booth',
  'Henry Russel',
  'James Fell',
  'Jamie Pullar',
  'John Lyon',
  'Keith Rodrigo',
  'Leticia Francis',
  'Lily Miller',
  'Maggie Xi',
  'Matt Bossley',
  'Mirelle Mhanna',
  'Quim Bolos',
  'Redan Yelden',
  'Rob Byron',
  'Ross Hill',
  'Ryan Cahill',
  'Sachin Turakhia',
  'Tim Wintle',
  'Tom Adams',
  'Will Ingram',
  'Will Newitt'
];

class EloHistoryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eloHistory: [],
      loading: true,
      minScore: 1400,
      selectedPlayer: null,
      opponentData: {}  // Store data against opponents
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = (selectedPlayer = null) => {
    if (!selectedPlayer) {
      this.setState({ eloHistory: [], loading: false });
      return;
    }

    fetch('/table/')
      .then(response => response.json())
      .then(data => {
        const eloHistory = data.players_data_elo_history;

        const filteredEloHistory = eloHistory.filter(player =>
          selectedPlayer === player.player_name
        );

        const min = Math.min(
          ...filteredEloHistory.flatMap(player => player.scores.map(score => score.score))
        );

        fetch('/selected_player/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ selectedPlayer })
        })
          .then(response => response.json())
          .then(data => {
            this.setState({
              eloHistory: filteredEloHistory,
              minScore: min * 0.95,
              loading: false,
              opponentData: data.results  // Store opponent data
            });
          })
          .catch(error => {
            console.error('Error fetching card data:', error);
            this.setState({ loading: false });
          });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        this.setState({ loading: false });
      });
  };

  handlePlayerChange = (event, value) => {
    this.setState({ selectedPlayer: value });
    this.fetchData(value);
  };

  render() {
    const { loading, selectedPlayer, opponentData } = this.state;

    // Separate opponents by win/loss record
    const winsMore = [];
    const lossesMore = [];

    Object.entries(opponentData).forEach(([opponentName, stats]) => {
      if (stats.player_wins > stats.player_losses) {
        winsMore.push({ opponentName, ...stats });
      } else {
        lossesMore.push({ opponentName, ...stats });
      }
    });

    return (
      <div style={{ padding: '20px' }}>
        <h1 style={{ textAlign: 'center' }}>Player Statistics</h1>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          <div style={{ textAlign: 'center' }}>
            <Autocomplete
              options={playerSuggestions}
              getOptionLabel={(option) => option}
              onChange={this.handlePlayerChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Player"
                  placeholder="Select Player"
                />
              )}
              style={{ width: '300px' }}
            />
          </div>
        </div>
        {!loading && selectedPlayer && (
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
            <div style={{ width: '45%', textAlign: 'center' }}>
              <h2>More Wins</h2>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {winsMore.map(({ opponentName, games_played, player_wins, player_losses, player_potted_balls, opponent_potted_balls }) => (
                  <PlayerStatsCard
                    key={opponentName}
                    playerName={opponentName}
                    gamesPlayed={games_played}
                    wins={player_wins}
                    losses={player_losses}
                    pottedBalls={player_potted_balls}
                    opponentPottedBalls={opponent_potted_balls}
                    style={{ flexBasis: 'calc(33.33% - 20px)', margin: '10px' }}
                  />
                ))}
              </div>
            </div>
            <div style={{ width: '10%', textAlign: 'center', paddingTop: '20px' }}>
            
            </div>
            <div style={{ width: '45%', textAlign: 'center' }}>
              <h2>More Losses</h2>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {lossesMore.map(({ opponentName, games_played, player_wins, player_losses, player_potted_balls, opponent_potted_balls }) => (
                  <PlayerStatsCard
                    key={opponentName}
                    playerName={opponentName}
                    gamesPlayed={games_played}
                    wins={player_wins}
                    losses={player_losses}
                    pottedBalls={player_potted_balls}
                    opponentPottedBalls={opponent_potted_balls}
                    style={{ flexBasis: 'calc(33.33% - 20px)', margin: '10px' }}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default EloHistoryComponent;
